.static {
  font-size: rem(24px);
  line-height: 1.5;
  color:cl(black);
  font-family: $font;
  font-weight: 300;
  padding: rem(70px 0 50px 0);
  @include media-breakpoint-down(sm){
    font-size: rem(20px)
  }
  p {
    margin: rem(0 0 30px);
  }

  ol, ul {
    margin: rem(0 0 20px);
    padding: rem(0 0 0 40px);
  }
  ul{
    list-style: none;
    position: relative;
    z-index: 3;
    margin-bottom: 1.5rem;
    li{
      position: relative;
      padding: rem(6px 0 6px 15px);
      &:after{
        content: '';
        position: absolute;
        top:rem(18px);
        left: 0;
        width: rem(7px);
        height: rem(7px);
        border-radius: 50%;
        line-height: rem(20px);
        background-color: cl(primary);
      }
    }
  }
  img {
    display: block;
    margin: 0 auto 2rem;
    max-width:90%;
    height: auto;
  }
}