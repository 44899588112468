.form {
  &-group {
    margin:rem(0 0 40px);
    position: relative;
    &.form-group-big{
      margin:rem(0 0 20px);
      .custom-select {
        height: rem(74px);
        border-radius: rem(50px);
      }
      .form-control{
        border-radius: rem(50px);
        border:rem(1.5px solid #DBE3EA);
        font-size: rem(16px);
        color: cl(text-blue);
        padding: rem(0 20px 0 38px);
        position: relative;
        @include placeholder(rgba(146, 146, 146, 1));
        &:focus{
          border: rem(1.5px solid #00486C);
        }
        &.name{
          padding: rem(0 20px 0 69px);
          background: #ffffff url(../img/svg/person.svg) no-repeat left 2rem center;
          background-size: 1.5rem auto;
        }
        &.house{
          padding: rem(0 20px 0 69px);
          background: #ffffff url(../img/svg/house.svg) no-repeat left 2rem center;
          background-size: 1.5rem auto;
        }
        &.mail{
          background: #ffffff url(../img/svg/email.svg) no-repeat left 2rem center;
          background-size: 1.5rem auto;
          padding: rem(0 20px 0 69px);
        }
        &.phone{
          background: #ffffff url(../img/svg/appel-telephonique1.svg) no-repeat left 2rem center;
          background-size: 1.5rem auto;
          padding: rem(0 20px 0 69px);
        }
        &.pen{
          background: #ffffff url(../img/svg/stylo1.svg) no-repeat left 2rem top 1.5rem;
          background-size: 1.5rem auto;
          padding: rem(26px 20px 0 69px);
        }
      }
      .form-label{
        font-size: rem(18px);
        line-height: 27px;
        letter-spacing: rem(0.5px);

        color: cl(text-blue);
      }
    }
    textarea.form-control {
      resize: none;
      padding-top: 1rem;
      padding-bottom: 1rem;
      height:rem(170px);
    }
    .view-pass {
      position: absolute;
      top: 1rem;
      right:rem(12px);
      color: cl(black);
      line-height: rem(16px);
      background: none;
      border:0;
      cursor: pointer;
      &:hover{
        color: cl(black, 0.6);
      }
      &:focus {
        outline: none;
        color: cl(primary);
      }
      i{
        font-size: 0.9rem;
      }
      .fa-eye-slash{
        display: block;
      }
      .fa-eye{
        display: none;
      }
      &.active{
        .fa-eye-slash{
          display: none;
        }
        .fa-eye{
          display: block;
        }
      }
    }
    .custom-select {
      background:  right .5rem center/ rem(16px auto) no-repeat url(../img/svg/arrow-down.svg), cl(white);
      padding-right: rem(25px);
      display: block;
      width:100%;
      font-family: $font;
      height: rem(74px);
      border-radius: rem(50px);
      border:rem(1px solid #DBE3EA);
      font-size:rem(15px);
      color:cl(black);
      @include placeholder(#a6a6a6);
      &:focus {
        outline: none;
        box-shadow:none;
        border-color:cl(primary);
      }
    }
  }
  &-label {
    display: block;
    color:cl(text);
    font-size: rem(15px);
    margin:rem(0 0 5px);
    font-family: $font;
  }
  &-control{
    display: block;
    width:100%;
    font-family: $font;
    height: rem(74px);
    background: cl(white);
    border-radius: rem(50px);
    border:rem(1px solid #DBE3EA);
    padding:rem(0 16px);
    font-size:rem(15px);
    color:cl(black);
    @include placeholder(rgba(146, 146, 146, 1));
    &:focus {
      outline: none;
      box-shadow:none;
      background: cl(white);
      border-color:cl(primary);
    }
  }


  &-check {
    padding: 0;
    margin: 0;
    position: relative;
    &-input-checkbox {
      width: rem(22px);
      height: rem(22px);
      position: absolute;
      z-index: 3;
      opacity: 0;
      left:0;
      top:0;
      @extend .inline-b;
      & + .form-check-label {
        font-weight: normal;
        @extend .inline-b;
        padding: rem(0 0 0 34px);
        margin: 0;
        position: relative;
        font-size: rem(15px);
        line-height: rem(22px);
        color:cl(black);
        &:before {
          content: '';
          position: absolute;
          left:0;
          top:0;
          width: rem(22px);
          height: rem(22px);
          border-radius: rem(0);
          background: cl(white);
          border:rem(1px solid #e5e5e5);
        }
        &:after {
          content: '\f00c';
          position: absolute;
          top:0;
          left: 0;
          width: rem(22px);
          height: rem(22px);
          line-height: rem(22px);
          text-align: center;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          opacity: 0;
          font-size: rem(14px);
          color: cl(primary);
        }
      }
      &:checked {
        & + .form-check-label {
          &:before {
            //background: cl(primary);
            //border-color: cl(primary);
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
    &-input-radio {
      width: rem(22px);
      height: rem(22px);
      position: absolute;
      z-index: 3;
      opacity: 0;
      left:0;
      top:0;
      @extend .inline-b;
      & + .form-check-label {
        font-weight: normal;
        @extend .inline-b;
        padding: rem(0 0 0 34px);
        margin: 0;
        position: relative;
        font-size: rem(15px);
        line-height: rem(22px);
        color:cl(black);
        &:before {
          content: '';
          position: absolute;
          left:0;
          top:0;
          width: rem(22px);
          height: rem(22px);
          border:rem(1px solid #e5e5e5);
          border-radius: 50%;
          background: cl(white);
        }
      }
      &:checked {
        & + .form-check-label {
          &:before {
            border-color: cl(primary);
            border-width:rem(7px);
          }
        }
      }
    }
  }
  .cards-list {
    display: flex;
    align-items: center;
    min-height: rem(44px);
    img {
      margin: rem(0 3px 0 0);
      width:rem(30px);
      height: auto;
    }
  }
}
