@font-face {
  font-family: 'Trajan Pro 3';
  src: url('../fonts/TrajanPro3Regular.eot');
  src: url('../fonts/TrajanPro3Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TrajanPro3Regular.woff2') format('woff2'),
  url('../fonts/TrajanPro3Regular.woff') format('woff'),
  url('../fonts/TrajanPro3Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../fonts/BrandonGrotesque-Regular.eot');
  src: url('../fonts/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
  url('../fonts/BrandonGrotesque-Regular.woff') format('woff'),
  url('../fonts/BrandonGrotesque-Regular.ttf') format('truetype'),
  url('../fonts/BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../fonts/BrandonGrotesque-Medium.eot');
  src: url('../fonts/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
  url('../fonts/BrandonGrotesque-Medium.woff') format('woff'),
  url('../fonts/BrandonGrotesque-Medium.ttf') format('truetype'),
  url('../fonts/BrandonGrotesque-Medium.svg#BrandonGrotesque-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../fonts/BrandonGrotesque-Black.eot');
  src: url('../fonts/BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BrandonGrotesque-Black.woff2') format('woff2'),
  url('../fonts/BrandonGrotesque-Black.woff') format('woff'),
  url('../fonts/BrandonGrotesque-Black.ttf') format('truetype'),
  url('../fonts/BrandonGrotesque-Black.svg#BrandonGrotesque-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../fonts/BrandonGrotesque-Bold.eot');
  src: url('../fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
  url('../fonts/BrandonGrotesque-Bold.woff') format('woff'),
  url('../fonts/BrandonGrotesque-Bold.ttf') format('truetype'),
  url('../fonts/BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

