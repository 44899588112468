/* banner start */
.banner {
  position: relative;
  background: center/cover no-repeat;
  color: cl(white);

  &--home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    background: center/cover no-repeat;
    background-attachment: fixed;
    padding: rem(90px 300px 0px 215px);
    @include media-breakpoint-down(lg) {
      padding: rem(190px 30px 190px 30px);
      height: auto;
    }
    @include media-breakpoint-down(md) {
      background-attachment:scroll;
    }

    &:after{
      content: "";
      @extend .full;
      background-color: cl(primary, 0.48);
      position: absolute;
      background: linear-gradient(331deg, rgba(4,42,79,1) 29%, rgba(4,42,79,0.28) 100%);
    }
    .screens{
      position: absolute;
      right: 0;
      bottom: 4vh;
      width: 41.3vw;
      z-index: 4;
      max-height: 80vh;
      object-fit: contain;
      object-position: right bottom;
      @include media-breakpoint-down(lg) {
        bottom: 1rem;
      }
    }
    .banner__text {
      position: relative;
      width:100%;
      height: 100%;
      color:cl(white);
      z-index: 6;
      display: flex;
      justify-content:center;
      align-items: flex-start;
      flex-direction: column;
      font-weight: 300;
      font-size: rem(26px);
      line-height: 1.8;
      @include media-breakpoint-down(xs) {
        padding: 0;
      }
      .banner__ttl{
        font-size: rem(50px);
        line-height: 1.25;
        color: cl(white);
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: rem(0.5px);
        margin-bottom: rem(0px);
        @include media-breakpoint-down(md) {
          font-size: rem(40px);
          line-height: rem(50px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(30px);
          line-height: rem(40px);
          margin-bottom: 0.5rem;
        }
      }
      .banner__sub-ttl{
        font-size: rem(40px);
        line-height: rem(70px);
        color: cl(white);
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: rem(0.5px);
        margin-bottom: rem(25px);
        @include media-breakpoint-down(md) {
          font-size: rem(30px);
          line-height: rem(40px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(25px);
          line-height: rem(40px);
        }
      }
      .list-text{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap:0 2.5rem;
        font-size:rem(22px);
        margin-bottom: 2.5rem;
        flex-wrap: wrap;
        @include media-breakpoint-down(xs) {
         display: inline-block;
        }
        img{
          width: rem(11px);
          margin-right: 0.5rem;
        }
        .d-flex{
          align-items: center;
          justify-content: flex-start;

        }
        @include media-breakpoint-down(xs) {
          text-align: center;
        }
      }
      b{
        font-weight: 700;
      }
      p {
        margin-bottom: rem(30px);
        padding-right: rem(60px);
        @include media-breakpoint-down(md) {
          padding-right:0;
        }
        @include media-breakpoint-down(xs) {
          text-align: center;
        }
      }
      .btn{
        margin-top: rem(20px);
      }
    }
    .banner__text-side{
      max-width: rem(1020px);
      gap: 0.5rem .5rem;
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
    .banner__text-wrap{
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
      .banner-socials{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: rem(38px);
        margin-right: rem(45px);
        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-right: 0;
          order: 1;
          margin-top: 2rem;
        }
        .follow-letters{
          font-size: rem(20px);
          font-weight: 300;
          @extend .ttu;
          text-orientation: upright;
          white-space: nowrap;
          transform: rotate(-90deg);
          margin-bottom: 2.6rem;
          letter-spacing: 0.089em;
          @include media-breakpoint-down(xs) {
           transform: none;
            margin-bottom: 1rem;
            width: 100%;
            text-align: center;
          }
        }
        .line{
          width: 1px;
          background-color: cl(white);
          height: rem(104px);
          margin: rem(12px 0);
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
        .social{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          gap: 0.5rem .5rem;
          @include media-breakpoint-down(xs) {
            flex-direction: row;
            gap: 1rem 1rem;
          }
          a{
            color: cl(white);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: rem(16px);
            border: 1px solid white;
            width: rem(36px);
            height: rem(36px);
            border-radius: 50%;

            &:hover{
              text-decoration: none;
              background-color: cl(white);
              color: cl(primary);
            }
          }
        }
      }

    }
  }
  &--page {
    height: rem(500px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: rem(50px 0 10px 0);
    background:center/cover no-repeat, ;
    color: cl(white);
    @include media-breakpoint-down(sm){
      height: rem(350px);
    }
    .container {
      position: relative;
      z-index: 2;
    }
    &:after{
      content:"";
      @extend .full;
      background-color: rgba(0, 72, 108, .2)
    }
    .banner__text {
      position: relative;
      width:100%;
      height: unset;
      color:cl(white);
      z-index: 6;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 0;
      .banner__ttl{
        font-size: rem(50px);
        line-height: 1.25;
        color: cl(white);
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: rem(0.5px);
        margin-bottom: rem(0px);
        @include media-breakpoint-down(md) {
          font-size: rem(40px);
          line-height: rem(50px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(30px);
          line-height: rem(40px);
          margin-bottom: 0.5rem;
        }
      }
      .banner__subtext{
        padding: 0 1.25rem;
        font-weight: 300;
        font-size: rem(24px);
        line-height: 1.6;
        margin-bottom: rem(-130px);
        margin-top: rem(20px);
        @include media-breakpoint-down(sm){
          font-size: rem(18px);
          margin-bottom: rem(-60px);
        }
        a{
          color: cl(white);
          display: block;
          text-align: center;
        }
      }
      p {
        margin: 0;
      }

    }
  }
  &-video {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    //position: absolute;
    z-index: 0;
    @include media-breakpoint-down(md) {
      position: absolute;
    }
    &.banner-video-absolute{
      position: absolute;
    }
    //&:after{
    //  content: "";
    //  @extend .full;
    //  background-color: cl(black, 0.2);
    //}
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &__btns-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(45px);
    margin-top: 2rem;
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
    .btn{
      min-width: rem(190px);
    }
    .watch-video-banner{
      .watch{
        display: block;
      }
      .stop{
        display: none;
      }
      &.stop-video{
        .watch{
          display: none;
        }
        .stop{
          display: block;
        }
      }

    }
  }
  .scroll-to-section{
    position: absolute;
    z-index: 14;
    @extend .pos-centerX;
    bottom: 1rem;
    img{
      height: rem(29px);
    }
  }
}
/* banner end */

