.home--second-section{
  padding: rem(81px 0 40px 0);
  font-size: rem(30px);
  font-weight: 300;
  background-color: cl(white);
  z-index: 1;
  position: relative;
  p{
    margin-bottom: rem(27px);
  }
  b,strong{
    font-weight: 600;
  }
  span{
    font-weight: 400;
  }
  h2 {
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  .left-text-wrap{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: rem(50px);
    line-height: rem(44px);
    font-weight: 700;
    @extend .ttu;
    text-orientation: upright;
    white-space: nowrap;
    @include media-breakpoint-down(md){
      justify-content: center;
      margin-bottom: 2rem;
    }
    span{
      margin-bottom: 0.75rem;
      display: block;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .vertical-word{
      transform: rotate(-90deg);
      color: cl(secondary);
      width: rem(50px);
      letter-spacing: 0.089em;
    }
  }
}
.home--do-section{
  padding: rem(80px 0 65px 0);
  font-size: rem(22px);
  font-weight: 300;
  background-color: cl(white);
  line-height: 1.5;
  z-index: 1;
  position: relative;
  .pic-links-list{
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2.25rem;
    flex-wrap: wrap;
    margin-bottom: rem(90px);
    @include media-breakpoint-down(sm){
      justify-content: center;
      margin-bottom: rem(50px);
    }
    li{
      color: cl(primary);
      font-weight: 300;
      font-size: rem(22px);
      position: relative;
      padding: rem(4px 0 7px 0);

      &:before{
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left:50%;
        transform: translateX(-50%);
        background-color: #0046FD;
        transition: 0.3s ease-in-out;
      }
      &:hover{
        &:before{
          width: calc(100% + 6px);
        }
      }
      &:nth-child(2){
        &:before{
          background-color: #DB395A;
        }
      }
      &:nth-child(3){
        &:before{
          background-color: #FF9933;
        }
      }
      &:nth-child(4){
        &:before{
          background-color: #319A26;
        }
      }
      &:nth-child(5){
        &:before{
          background-color: #CB001C;
        }
      }
      &:nth-child(6){
        &:before{
          background-color: #68379A;
        }

      }
      &:nth-child(7){
        &:before{
          background-color: #B2CE05;
        }

      }
      a{
        color: cl(primary);
        white-space: nowrap;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .image-side{
    position: relative;
    width: rem(1080px);
    margin-left: rem(-44px);
    @include media-breakpoint-down(lg){
      width: 100%;
      margin: 0 auto 2rem;
    }
    img{
      width: 100%;
    }
  }
  .text-side{
    //padding-top: 1.75rem;
    //padding-left: rem(40px);
    @include media-breakpoint-down(md){
      padding-left:0;
    }
    h2 {
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
    p{
      margin-bottom: rem(18px);
    }
    .btns-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.4rem 1.4rem;
      margin-top: 2.5rem;
      @include media-breakpoint-down(xs) {
        justify-content: center;
        gap: 1.4rem 4%;
      }
      .btn {
        @include media-breakpoint-down(xs) {
          width: 47%;
        }
      }
    }
  }
}
.home--vertual-section{
  padding: rem(70px 0 8.5vw 0);
  font-size: rem(22px);
  line-height: 1.5;
  font-weight: 300;
  background-color: cl(gray-bg);
  z-index: 1;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: rem(50px 0 110px 0);
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 22vw;
  }
  .flex-wrapper{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
  }
  .image-side{
    position: relative;
    width: 58%;
    @include media-breakpoint-down(md){
      width: 100%;
    }
    @include media-breakpoint-down(md){
      margin: 0 auto 2rem;
      float: none;
      width: 80%;
    }
    @include media-breakpoint-down(xs){
      margin: 0 auto 2rem;
      float: none;
      width: 100%;
    }
    img{
      width: 103%;
      @include media-breakpoint-down(lg){
        width: 100%;
      }
    }
    .arrow{
      position: absolute;
      top: 0;
      left: -20%;
      width: rem(238px);
      transform: rotateY(180deg);
      @include media-breakpoint-down(md){
        display: none;
      }
    }
  }
  .text-side{
   padding-top: 2.75rem;
    font-weight: 300;
    //padding-right: rem(40px);
    width: 42%;
    @include media-breakpoint-down(md){
      padding-left:0;
      width: 100%;
      order: 1;
      padding-top: 0;
    }
    h2 {
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
    p{
      margin-bottom: rem(28px);
    }
    .btns-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.4rem 1.4rem;
      margin-top: 2.5rem;
      @include media-breakpoint-down(xs) {
        justify-content: center;
        gap: 1.4rem 4%;
      }
      .btn {
        @include media-breakpoint-down(xs) {
          width: 47%;
        }
      }
    }
  }
  .bg-text{
    position: absolute;
    top: 85%;
    left: -7.4vw;
    font-size:8.8vw;
    line-height: 100%;
    color: rgba(0, 72, 108, 0.07);
    font-weight: 100;
    white-space: nowrap;
    @include media-breakpoint-down(lg){
      left: 3vw;
      top: 88%;
    }
    @include media-breakpoint-down(md) {
      left: 50%;
      transform: translateX(-50%);
      top: 102%;
      font-size: 16.8vw;
    }
  }
}
.home--market-section{
  padding: rem(110px 0 110px 0);
  font-size: rem(22px);
  line-height: 1.5;
  font-weight: 300;
  z-index: 1;
  background-color: cl(white);
  position: relative;
  @include media-breakpoint-down(md) {
    padding: rem(80px 0 80px 0);
  }
  @include media-breakpoint-down(xs) {
    padding: rem(50px 0 50px 0);
  }
  .flex-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: rem(-70px);
    position: relative;
    @include media-breakpoint-down(lg) {
      margin-right:0;
    }
  }
  .text-side{
    font-weight: 300;
    //padding-right: rem(40px);
    width: 42%;
    @include media-breakpoint-down(md){
      padding-left:0;
      width: 100%;
      order: 1;
      padding-top: 0;
    }
    @include media-breakpoint-down(md){
      padding-right:0;
    }
    h2 {
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
    p{
      margin-bottom: rem(18px);
    }
    .btns-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.4rem 1.4rem;
      margin-top: 2.5rem;
      @include media-breakpoint-down(xs) {
        justify-content: center;
        gap: 1.4rem 4%;
      }
      .btn {
        @include media-breakpoint-down(xs) {
          width: 48%;
        }
      }
    }
  }
  .image-side{
    position: relative;
    width: 58%;
    @include media-breakpoint-down(md){
      width: 100%;
      margin: 0 auto 2rem;
    }
   // margin-left: -8rem;
   // display: flex;
   // align-items: flex-end;
   // justify-content: flex-start;
    img{
      width: 45.3vw;
      object-fit: cover;
      min-height: 24vw;
      border-radius:  rem(10px);
      @include media-breakpoint-down(lg){
        width: 100%;
      }
    }
    .video-wrap{
      float: right;
      position: relative;
      padding-left: rem(44px);
      margin-right: rem(100px);
      @include media-breakpoint-down(md) {
        float: none;
        padding-left: 0;
        margin-right: 0;
      }
      &:after{
        content: "";
        clear: both;
      }
    }
    .vertical-word{
      color: #D9DAD9;
      width: 3.6rem;
      font-size: 3.3vw;
      font-weight: 100;
      position: absolute;
      left: 0;
      @extend .ttu;
      top: 50%;
      transform: rotate(-90deg) translateX(-252%) translateY(-10%);
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .video-holder{
      flex-shrink: 0;
      position: relative;
      width: 45.3vw;
      padding-bottom: 56.25%;
      display: block;
      z-index: 8;
      border-radius:  rem(10px);
      overflow: hidden;
      background: center/ cover , no-repeat;
      @include media-breakpoint-down(lg) {
        padding-bottom: 49%;
      }
      @include media-breakpoint-down(md) {
        margin: 0 auto;
        width: 90%;
        padding-bottom: 56.6%;
      }
      @include media-breakpoint-down(xs) {
        margin: 0 auto;
        width: 100%;
        padding-bottom: 56.6%;
      }
      &:after{
        content: "";
        clear: both;
      }
      .poster-cover{
        width: 100%;
        border-radius: rem(10px);
        height: 100%;
        object-fit: cover;
        position: absolute;
        @extend .pos-center;
        z-index: 1;
      }
      video{
        border-radius: rem(10px);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        @extend .pos-center;
      }
      iframe{
        width: 100%!important;
        height: 100%!important;
        z-index: 0;
        position: absolute;
        @extend .pos-center;
      }
      &.active{
        .poster-cover{
          display: none;
        }
        .play{
          display: none;
        }
        &:hover{
          cursor: pointer;
          .pause{
            display: block;
          }
        }
      }
      &:hover{
        cursor: pointer;
        .play{
          img {
            width: rem(78px);
            height: rem(78px);
            @include media-breakpoint-down(md) {
              width: rem(58px);
              height: rem(58px);
            }
          }
        }
      }
      .play{

        position: absolute;
        @extend .pos-center;
        z-index: 4;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: 0.5s ease-in-out;
        transform-origin: center;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          width: rem(70px);
          height: rem(70px);
          position: relative;
          z-index: 4;
          opacity: 0.7;
          @include media-breakpoint-down(md) {
            width: rem(50px);
            height: rem(50px);
          }
        }
      }
    }
  }
}

.home-tools-section{
  padding: rem(70px 0 70px 0);
  font-size: rem(22px);
  line-height: 1.5;
  font-weight: 300;

  background-color: cl(gray-bg);
  z-index: 1;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    padding: rem(50px 0 2px 0);
  }
  .bg-text{
    color: rgba(0, 72, 108, 0.07);
    font-size: 6.9vw;
    line-height: 6.9vw;
    margin-bottom: -1.1vw;
    font-weight: 100;
    white-space: nowrap;
    text-align: center;
  }
  h2 {
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  p{
    margin-bottom: 2rem;
  }
  .tools-block-wrap{
    display: grid;
    gap:2rem;
    grid-template-columns: repeat(4,1fr);
    padding: 1rem 0;
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2,1fr);
    }
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1,1fr);
    }
    .item{
      background: #FFFFFF;
      border: 1px solid #8CA6B8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: rem(10px);
      font-weight: 400;
      font-size: rem(20px);
      line-height: rem(22px);
      text-align: center;
      color: #414141;
      padding: rem(34px 16px);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      &:before{
        content: "";
        top: -1px;
        z-index: 2;
        left: -1px;
        position: absolute;
        height: rem(12px);
        width: calc(100% + 2px);
        border-radius: rem(10px 10px 0px 0px);
      }
      &:first-child{
        &:before{
          background-color: #294E8D;
        }
      }
      &:nth-child(2){
        &:before{
          background-color: cl(red);
        }
      }
      &:nth-child(3){
        &:before{
          background-color: cl(yellow);
        }
      }
      &:nth-child(4){
        &:before{
          background-color: cl(purple);
        }
      }
      .ttl{
        font-size: rem(30px);
        line-height: rem(36px);
        color: cl(primary);
        margin-bottom: rem(10px);
      }
      p{
        padding: rem(0px 16px);
        margin-bottom: 0;
      }
      .btn{
        margin-top: 2rem;
      }
      .item-inner{
        flex-grow: 1;
      }
      img{
        width: rem(64px);
        height: rem(64px);
        object-fit: contain;
        object-position: center;
        margin-bottom: 1rem;
      }
    }
  }

}
.home--auditorium-section{
  padding: rem(60px 0 60px 0);
  font-size: rem(22px);
  line-height: 1.5;
  font-weight: 300;
  background-color: cl(white);
  z-index: 1;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: rem(50px 0 110px 0);
  }
  @include media-breakpoint-down(sm) {
    padding: rem(50px 0 0px 0);
  }
  .image-side{
    position: relative;
    width: 44.5vw;
    float: right;
   margin-right: -2.5rem;
    @include media-breakpoint-down(lg){
      width: 100%;
    }
    @include media-breakpoint-down(md){
      margin: 0 auto 2rem;
      float: none;
      width: 100%;
    }
    img{
      width: 100%;
      border-radius: rem(10px);
    }
    .arrow{
      position: absolute;
      top: -2rem;
      right: rem(-69px);
      width: rem(238px);
      @include media-breakpoint-down(md){
        display: none;
      }
    }
  }
  .text-side{
    padding-top: 2.8vw;
    padding-left: rem(70px);
    @media (min-width: 2191.98px){
      padding-left: rem(100px);
    }
    @include media-breakpoint-down(md){
      padding-left:0;
    }
    h2 {
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
    p{
      margin-bottom: rem(18px);
    }
    .btns-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.4rem 1.4rem;
      margin-top: 2.5rem;
      @include media-breakpoint-down(xs) {
        justify-content: center;
        gap: 1.4rem 4%;
      }
      .btn {
        @include media-breakpoint-down(xs) {
          width: 47%;
        }
      }
    }
    .letters{
      font-weight: 100;
      color: rgba(0, 72, 108, 0.07);
      font-size: rem(150px);
      font-size: 8vw;

      margin-top: -5.6vw;
      @include media-breakpoint-down(sm){
        font-size: 18vw;
        margin-top: 1rem;
      }
    }
  }
  .bg-text{
    position: absolute;
    bottom: -6.8vw;
    left: 0;
    font-size:8.8vw;
    line-height: 150%;
    color: rgba(0, 72, 108, 0.07);
    font-weight: 100;
    white-space: nowrap;
    @include media-breakpoint-down(lg){
      bottom:-10.8vw;
    }
    @include media-breakpoint-down(sm) {
      left: 1rem;
      font-size: 16.8vw;
      bottom:-21vw;
    }
  }
}
.home-one-place{
  padding: rem(70px 0 10.5vw 0);
  font-size: rem(22px);
  line-height: 1.5;
  font-weight: 300;
  background-color: cl(gray-bg);
  z-index: 1;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    padding: rem(50px 0 8.6vw 0);
  }
  .bg-text{
    position: absolute;
    bottom: 1.8vw;
    @extend .pos-centerX;
    font-size:7.7vw;
    line-height: 9.2vw;
    color: rgba(0, 72, 108, 0.07);
    font-weight: 100;
    text-align: center;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      font-size:9.5vw;
    }
  }
  .one-place-items{
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: rem(40px 65px);
    flex-wrap: wrap;
    padding:rem(30px 0 0 0);
    .item{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-size: rem(20px);
      line-height: rem(28px);
      width: calc(33.33% - 2.75rem);
      font-weight: 400;
      min-height: rem(360px);
      @include media-breakpoint-down(md) {
        width: calc(50% - 2.75rem);
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      img{
        width: rem(78px);
        height: rem(80px);
        object-fit: contain;
        object-position: center top;
        margin-bottom: 1rem;
      }
      .ttl{
        font-size: rem(28px);
        font-weight: 700;
        margin-bottom: 1rem;
      }
      ul{
        list-style: none;
        margin: 0;
      }
      .item-cont{
        flex-grow: 1;
        position: relative;
        width: 100%;
        padding: rem(25px 0 0 40px);
        &:before{
          content: "";
          top:0;
          width: 100%;
          border-radius: rem(100px);
          height: rem(10px);
          background-color: cl(yellow);
          position: absolute;
          left: 0;
        }
        &:after{
          content: "";
          left: 0;
          bottom:0;
          height: calc(100% - 2rem);
          border-radius: rem(100px);
          width: 2px;
          background-color: cl(yellow);
          position: absolute;
        }
      }
      &:nth-child(2){
        .item-cont{
          &:before{
            background-color: cl(purple);
          }
          &:after{
            background-color: cl(purple);
          }
        }
      }
      &:nth-child(3){
        .item-cont{
          &:before{
            background-color: cl(red);
          }
          &:after{
            background-color: cl(red);
          }
        }
      }
    }
  }
}