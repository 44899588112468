
@keyframes animation-pulse-1 {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform:  scale(1.1) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
@-webkit-keyframes animation-pulse-1{
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform:  scale(1.1) rotate(10deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
}


@keyframes animation-line-2 {
  0% {
    transform: translateX(-50%);
  }
  25% {
    transform: translateX(-80%);
  }
  75% {
    transform: translateX(-20%);
  }
  100% {
    transform:translateX(-50%);
  }
}
@-webkit-keyframes animation-line-2 {
  0% {
    transform: translateX(-50%);
  }
  25% {
    transform: translateX(-80%);
  }
  75% {
    transform: translateX(-20%);
  }
  100% {
    transform:translateX(-50%);
  }
}