.footer {
  background:cl(primary);
  position: relative;
  z-index: 2;
  color:cl(white);
  font-size: rem(20px);
  line-height: rem(26px);
  a{
    color: cl(white);
  }
  h6{
    @extend .ttu;
    margin-bottom: rem(16px);
  }
  .column{

    margin-bottom: 2rem;
    &:first-child{
      border: 0;
    }
    @include media-breakpoint-down(xs) {
      border: 0;
    }
    &-1{
      margin-bottom: 2rem;
      p {
        max-width: rem(480px);
      }
    }
  }

  &-copy {

    @include media-breakpoint-down(xs) {
      text-align: center;
      margin: 0 auto;
      display: block;
    }
  }
  &-logo{
    width: rem(347px);
    max-width: 100%;
    display: block;
    margin-bottom: rem(130px);
    @include media-breakpoint-down(lg) {
      margin-bottom: rem(30px);
    }
    img{
      width: 100%;
    }
  }
  &--top-line{
    padding: rem(90px 0 80px 0);
    @include media-breakpoint-down(sm) {
      padding: rem(90px 0 0px 0);
    }
  }
  .social{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 1.5rem;
    margin-top: 1.5rem;
    a{
      color: cl(white);
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(36px);
      height: rem(36px);
      border-radius: 50%;
      border: 1px solid ;
      font-size: rem(16px);
      &:hover{
        text-decoration: none;
        color: cl(primary);
        background-color: cl(white);
      }
    }
  }

  &--bottom-line{
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: rem(13px 0);
    font-size: rem(13px);
    @extend .ttu;
    font-weight: 400;
  }
  ul {
    list-style: none;
    margin: 0;
    & > li {
      position: relative;

      padding: rem(6px 0 6px 0px);
      & > a {
        display: block;
        position: relative;
        &:hover {
          text-decoration: underline;
        }
        i{
          font-size: rem(13px);
        }
      }
      &.active {
        & > a {
          text-decoration: none;
          color:cl(primary);
        }
      }
    }
  }
}
