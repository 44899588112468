$font: 'Brandon Grotesque';
$min-aspect169 : "(min-aspect-ratio: 16/9)";
$max-aspect169 : "(max-aspect-ratio: 16/9)";

$colors_theme : (
        "primary"   : "primary",
        "secondary" : "secondary",
        "text" : "text",
        "gray-bg" : "gray-bg",
        "black" : "black",
        "white" : "white",
        "yellow": "yellow",
        "purple" : "purple",
        "red" : "red",
        'text-blue': 'text-blue',
);


$colors : (
        'primary'  : #003462,
        'secondary'  : #4d93b8,
        "red" : #db395a,
        "yellow" : #fbae42,
        "purple" : #ae619d,
        'text'  : #000000,
        'gray-bg'  : #f5f5f6,
        'white' : #FFFFFF,
        'black' : #000000,
        'green' : #71b263,
        'text-blue': #061237,
);

.color {
  @each $status, $color in $colors {
    &--#{$status} {
      color: $color!important;
    }
  }
}

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@function cl($name, $opacity: false) {
  @if $opacity {
    @return rgba(var(--color-#{unquote($name)}--rgb), $opacity);
  } @else {
    @return var(--color-#{unquote($name)});
  }
}

:root{
  @if $colors {
    @if $colors_theme {
      @each $key, $value in $colors_theme {
        --color-#{$key} : var(--color-#{$value});
        --color-#{$key}--rgb : var(--color-#{$value}--rgb);
      }
    }

    @each $key, $value in $colors {
      --color-#{$key} : #{$value};
      --color-#{$key}--rgb : #{HexToRGB($value)};
    }
  }
}