.centred-text-section{
  padding: rem(60px 0 40px 0);
  font-weight: 300;
  line-height: 1.5;
  font-size: rem(24px);
  h5{
    font-weight: 600;
    margin-bottom: 2rem;
    color: cl(primary);
  }
  p{
    margin-bottom: 1.5rem;
  }
  .container{
    max-width: rem(1043px);
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
}
.inner-page-tools-section{
  padding: rem(60px 0 20px 0);
  font-size: rem(22px);
  line-height: 1.5;
  font-weight: 300;
  z-index: 1;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    padding: rem(50px 0 20px 0);
  }
  &-network{
    padding: rem(60px 0 50px 0);
  }

  .bg-text{
    color: rgba(0, 72, 108, 0.07);
    font-size: 6.9vw;
    line-height: 6.9vw;
  margin-top: -0.9vw;
    font-weight: 100;
    white-space: nowrap;
    text-align: center;
  }
  h2 {
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  p{
    margin-bottom: 2rem;
  }
  .tools-block-wrap{
    display: grid;
    gap:2rem;
    grid-template-columns: repeat(4,1fr);
    padding: 2rem 0;
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2,1fr);
    }
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1,1fr);
    }
    &.network-tools-block-wrap{
      .item{
        .ttl{
          font-size: rem(28px);
          line-height: rem(36px);
          color: cl(primary);
          min-height: rem(70px);
          margin-bottom: rem(20px);
        }
      }
    }

    .item{
      background: #FFFFFF;
      border: 1px solid #8CA6B8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: rem(10px);
      font-weight: 400;
      font-size: rem(20px);
      line-height: rem(24px);
      text-align: center;
      color: #414141;
      padding: rem(34px 16px 56px 16px);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      &:before{
        content: "";
        top: -1px;
        z-index: 2;
        left: -1px;
        position: absolute;
        height: rem(12px);
        width: calc(100% + 2px);
        border-radius: rem(10px 10px 0px 0px);
      }
      &:first-child{
        &:before{
          background-color: #294E8D;
        }
      }
      &:nth-child(2){
        &:before{
          background-color: cl(red);
        }
      }
      &:nth-child(3){
        &:before{
          background-color: cl(yellow);
        }
      }
      &:nth-child(4){
        &:before{
          background-color: cl(purple);
        }
      }
      .ttl{
        font-size: rem(28px);
        line-height: rem(36px);
        color: cl(primary);
        margin-bottom: rem(20px);
      }
      p{
        padding: rem(0px 16px);
        margin-bottom: 0;
      }
      .btn{
        margin-top: 2rem;
      }
      .item-inner{
        flex-grow: 1;
      }
      img{
        width: rem(64px);
        height: rem(64px);
        object-fit: contain;
        object-position: center;
        margin-bottom: 1rem;
      }
    }
  }
}
.exhibitor-benefits-section{
  padding: rem(70px 0 20px 0);
  font-size: rem(18px);
  line-height: 1.5;
  font-weight: 300;
  background-color: cl(gray-bg,0.2);
  z-index: 1;
  position: relative;
  .bg-text{
    color: rgba(0, 72, 108, 0.07);
    font-size: 6.9vw;
    line-height: 6.9vw;
    font-weight: 100;
    white-space: nowrap;
    text-align: center;
  }
  h2 {
    text-align: center;
  }
  .benefits-list{
    display: flex;
    justify-content: space-between;
    list-style: none;
    flex-wrap: wrap;
    font-size: rem(24px);
    line-height: rem(28px);
    font-weight: 400;
    margin-top: 3.5rem;
    li{
      width: calc(50% - 3rem);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.5rem;
      @include media-breakpoint-down(sm) {
       width: 100%;
      }
      .icon{
        width: rem(80px);
        height: rem(80px);
        border-radius: 50%;
        margin-right: 1.5rem;
        flex-shrink: 0;
        background: center /3rem  no-repeat, white;
      }
    }
  }
}

.picture-text-section{
  padding: rem(90px 0 90px 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: rem(22px);
  min-height: rem(670px);
  line-height: 1.5;
  font-weight: 300;
  z-index: 1;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: rem(50px 0 50px 0);
    min-height: unset;
  }
  @include media-breakpoint-down(sm) {
    padding: rem(50px 0 50px 0);
  }
  .video-holder{
    flex-shrink: 0;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    display: block;
    z-index: 8;
    border-radius:  rem(10px);
    overflow: hidden;
    background: center/ cover , no-repeat;
    @include media-breakpoint-down(md){
      margin: 0 auto 2rem;
      float: none;
      width: 100%;
    }

    @include media-breakpoint-down(xs) {
      margin: 0 auto;
      width: 100%;
      padding-bottom: 56.6%;
    }
    &:after{
      content: "";
      clear: both;
    }
    .poster-cover{
      width: 100%;
      border-radius: rem(10px);
      height: 100%;
      object-fit: cover;
      position: absolute;
      @extend .pos-center;
      z-index: 1;
    }
    video{
      border-radius: rem(10px);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      @extend .pos-center;
    }
    iframe{
      width: 100%!important;
      height: 100%!important;
      z-index: 0;
      position: absolute;
      @extend .pos-center;
    }
    &.active{
      .poster-cover{
        display: none;
      }
      .play{
        display: none;
      }
      &:hover{
        cursor: pointer;
        .pause{
          display: block;
        }
      }
    }
    &:hover{
      cursor: pointer;
      .play{
        img {
          width: rem(78px);
          height: rem(78px);
          @include media-breakpoint-down(md) {
            width: rem(58px);
            height: rem(58px);
          }
        }
      }
    }
    .play{

      position: absolute;
      @extend .pos-center;
      z-index: 4;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: 0.5s ease-in-out;
      transform-origin: center;
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        width: rem(70px);
        height: rem(70px);
        position: relative;
        z-index: 4;
        opacity: 0.7;
        @include media-breakpoint-down(md) {
          width: rem(50px);
          height: rem(50px);
        }
      }
    }
  }
  .image-side{
    position: relative;
    width: 45.3vw;
    float: right;
    margin-left: -4rem;
    @include media-breakpoint-down(lg){
      width: 100%;

    }
    @include media-breakpoint-down(md){
      margin: 0 auto 2rem;
      float: none;
      width: 100%;
    }
    &.small-image-side{
      width: 35.93vw;
      margin-right: 1rem!important;
      @include media-breakpoint-down(lg){
        width: 100%;
      }
      @include media-breakpoint-down(md){
        margin: 0 auto 2rem;
        float: none;
        width: 100%;
      }
    }
    &.small-image-side-two{
      width: 35.93vw;
      margin-left: 4rem!important;
      @include media-breakpoint-down(lg){
        width: 100%;
        margin-left: auto!important;
      }
      @include media-breakpoint-down(md){
        margin: 0 auto 2rem;
        float: none;
        width: 100%;
      }
    }
    img{
      width: 100%;
      border-radius: rem(10px);
    }
    .arrow{
      position: absolute;
      top: -2rem;
      right: rem(-69px);
      width: rem(238px);
      @include media-breakpoint-down(md){
        display: none;
      }
    }
  }
  .text-side{
   // padding-top: 5.8vw;
    padding-left: rem(100px);
    @media (min-width: 2191.98px){
      padding-left: rem(100px);
    }
    @include media-breakpoint-down(lg){
      padding-right:2rem;
    }
    @include media-breakpoint-down(md){
      padding-left:0;
      padding-right: 0;
    }
    h2 {
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
    p{
      margin-bottom: rem(18px);
    }
    .btns-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.4rem 1.4rem;
      margin-top: 2.5rem;
      @include media-breakpoint-down(xs) {
        justify-content: center;
        gap: 1.4rem 4%;
      }
      .btn {
        @include media-breakpoint-down(xs) {
          width: 47%;
        }
      }
    }
    .letters{
      font-weight: 100;
      color: rgba(0, 72, 108, 0.07);
      font-size: 6.9vw;
      line-height: 7.4vw;
      margin-left: -0.9vw;
      width: 49vw;
      @include media-breakpoint-down(sm){
        font-size: 16vw;
        margin-top: 1rem;
      }
    }
    .text-height{
      min-height: rem(200px);
      @include media-breakpoint-down(md) {
       min-height: unset;
      }
    }

  }
  &.picture-text-section-reverse{
    .text-side{
      padding-left: 0;
      padding-right: rem(100px);
      @media (min-width: 2191.98px){
        padding-right: 0;
      }
      @include media-breakpoint-down(lg){
        padding-left:2rem;
      }
      @include media-breakpoint-down(md){
        padding-left:0;
        padding-right:0;
      }
    }
    .image-side{
      float: left;
      margin-right: -4rem;
      margin-left: unset;
      @include media-breakpoint-down(md){
        margin: 0 auto 2rem;
        float: none;
        width: 100%;
      }
    }
  }
  .bg-text{
    position: absolute;
    bottom: -6.8vw;
    left: 0;
    font-size:8.8vw;
    line-height: 150%;
    color: rgba(0, 72, 108, 0.07);
    font-weight: 100;
    white-space: nowrap;
    @include media-breakpoint-down(lg){
      bottom:-10.8vw;
    }
    @include media-breakpoint-down(sm) {
      left: 1rem;
      font-size: 16.8vw;
      bottom:-21vw;
    }
  }
}
.network-tools-block{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: rem(22px);
  line-height: 1.5;
  font-weight: 300;
  z-index: 1;
  position: relative;
  padding: rem(50px 0 20px 0);
  @include media-breakpoint-down(md) {

    min-height: unset;
  }
  @include media-breakpoint-down(sm) {
    padding: rem(50px 0 50px 0);
  }
  .image-side{
    position: relative;
    max-width: 100%;
    width: rem(573px);
    @include media-breakpoint-down(lg){
      width: 100%;

    }
    @include media-breakpoint-down(md){
      margin: 0 auto 2rem;
      float: none;
      width: 100%;
    }

    img{
      width: 100%;
      border-radius: rem(10px);
    }
  }
  .text-side{
    h2 {
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
    p{
      margin-bottom: rem(18px);
    }

    .letters{
      font-weight: 100;
      color: rgba(0, 72, 108, 0.07);
      font-size: 6.9vw;
      line-height: 7.4vw;
      margin-left: -0.9vw;
      width: 49vw;
      @include media-breakpoint-down(sm){
        font-size: 18vw;
        margin-top: 1rem;
      }
    }
    .text-height{
      min-height: rem(200px);
      @include media-breakpoint-down(md) {
       min-height: unset;
      }
    }

  }

}

.marketplace-section{
  padding: rem(110px 0 110px 0);
  font-size: rem(24px);
  line-height: 1.5;
  font-weight: 300;
  z-index: 1;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: rem(80px 0 80px 0);
  }
  @include media-breakpoint-down(xs) {
    padding: rem(50px 0 50px 0);
  }
  .flex-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: rem(-70px);
    position: relative;
    @include media-breakpoint-down(lg) {
      margin-right:0;
    }
  }
  .text-side{
    font-weight: 300;
    //padding-right: rem(40px);
    width: 50%;
    @include media-breakpoint-down(md){
      padding-left:0;
      width: 100%;
      order: 1;
      padding-top: 0;
    }
    @include media-breakpoint-down(md){
      padding-right:0;
    }
    h2 {
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
    p{
      margin-bottom: rem(18px);
    }
    .btns-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.4rem 1.4rem;
      margin-top: 2.5rem;
      @include media-breakpoint-down(xs) {
        justify-content: center;
        gap: 1.4rem 4%;
      }
      .btn {
        @include media-breakpoint-down(xs) {
          width: 48%;
        }
      }
    }
  }
  .image-side{
    position: relative;
    width: 50%;
    @include media-breakpoint-down(md){
      width: 100%;
      margin: 0 auto 2rem;
    }
    // margin-left: -8rem;
    // display: flex;
    // align-items: flex-end;
    // justify-content: flex-start;
    img{
      width: 38.5vw;
      object-fit: cover;
      min-height: 24vw;
      border-radius:  rem(10px);
      @include media-breakpoint-down(lg){
        width: 100%;
      }
    }
    .video-wrap{
      float: right;
      position: relative;
      padding-left: rem(44px);
      margin-right: rem(70px);
      @include media-breakpoint-down(md) {
        float: none;
        padding-left: 0;
        margin-right: 0;
      }
      &:after{
        content: "";
        clear: both;
      }
    }
    .vertical-word{
      color: #D9DAD9;
      width: 3.6rem;
      font-size: 3.8vw;
      font-weight: 100;
      position: absolute;
      left: 0;
      top: 50%;
      transform: rotate(-90deg) translateX(-226%) translateY(-10%);
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .video-holder{
      flex-shrink: 0;
      position: relative;
      width: 39.3vw;
      padding-bottom: 56.25%;
      display: block;
      z-index: 8;
      border-radius:  rem(10px);
      overflow: hidden;
      background: center/ cover , no-repeat;
      @include media-breakpoint-down(lg) {
        padding-bottom: 49%;
      }
      @include media-breakpoint-down(md) {
        margin: 0 auto;
        width: 90%;
        padding-bottom: 56.6%;
      }
      @include media-breakpoint-down(xs) {
        margin: 0 auto;
        width: 100%;
        padding-bottom: 56.6%;
      }
      &:after{
        content: "";
        clear: both;
      }
      .poster-cover{
        width: 100%;
        border-radius: rem(10px);
        height: 100%;
        object-fit: cover;
        position: absolute;
        @extend .pos-center;
        z-index: 1;
      }
      video{
        border-radius: rem(10px);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        @extend .pos-center;
      }
      iframe{
        width: 100%!important;
        height: 100%!important;
        z-index: 0;
        position: absolute;
        @extend .pos-center;
      }
      &.active{
        .poster-cover{
          display: none;
        }
        .play{
          display: none;
        }
        &:hover{
          cursor: pointer;
          .pause{
            display: block;
          }
        }
      }
      &:hover{
        cursor: pointer;
        .play{
          img {
            width: rem(78px);
            height: rem(78px);
            @include media-breakpoint-down(md) {
              width: rem(58px);
              height: rem(58px);
            }
          }
        }
      }
      .play{

        position: absolute;
        @extend .pos-center;
        z-index: 4;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: 0.5s ease-in-out;
        transform-origin: center;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          width: rem(70px);
          height: rem(70px);
          position: relative;
          z-index: 4;
          opacity: 0.7;
          @include media-breakpoint-down(md) {
            width: rem(50px);
            height: rem(50px);
          }
        }
      }
    }
  }
}
.marketplace-first-section{
  padding: rem(112px 0 90px 0);
  font-size: rem(24px);
  line-height: 1.5;
  font-weight: 300;
  z-index: 1;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: rem(80px 0 80px 0);
  }
  @include media-breakpoint-down(xs) {
    padding: rem(50px 0 50px 0);
  }
  .text-side{
    position: relative;
    padding-bottom:6.9vw;
  }
  .letters{
    font-weight: 100;
    color: rgba(0, 72, 108, 0.07);
    font-size: 6.9vw;
    line-height: 6.9vw;
    left: 64%;
    transform: translateX(-50%);
    margin-top: -1rem;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    @include media-breakpoint-down(lg) {
      left: 0;
      transform: none;
    }
    @include media-breakpoint-down(sm){
      font-size: 14vw;
      margin-top: 1rem;
    }
  }
  .marketplace-items{
    position: relative;
    width: rem(540px);
    height: rem(360px);
    margin-left: auto;
    @include media-breakpoint-down(lg){
      margin-right: 3rem;
    }
    @include media-breakpoint-down(md){
      margin: 0 auto 5rem;
    }
    @include media-breakpoint-down(sm){
      width: 90vw;
      height: unset;
    }
    .top-arrow{
      color: cl(primary);
      position: absolute;
      font-weight: 400;
      font-size: rem(18px);
      line-height:1;
      right: rem(26px);
      top:rem(-33px);
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      @include media-breakpoint-down(sm){
        display: none;
      }
      img{
        width: rem(183px);
      }

    }
    .bottom-arrow{
      color: cl(primary);
      position: absolute;
      font-weight: 400;
      font-size: rem(18px);
      line-height:1;
      right: rem(-26px);
      bottom:rem(-35px);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include media-breakpoint-down(sm){
        display: none;
      }
      img{
        width: rem(221px);
      }
    }
    .items-row{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      height: 50%;
      flex-shrink: 0;
      &.odd-row{
        .item{
          &:first-child{
            &:before {
              background-color: cl(purple);
            }
          }
          &:nth-child(2){
            &:before {
              background-color: cl(green);
            }
          }
          &:nth-child(3){
            &:before {
              background-color: cl(primary);
            }
          }
        }
      }
      &.even-row{
        .item{
          &:nth-child(1){
            &:after {
              background-color: cl(primary);
            }
          }
          &:nth-child(2){
            &:after {
              background-color: cl(red);
            }
          }
          &:nth-child(3){
            &:after {
              background-color: cl(yellow);
            }
          }

        }
      }
      .item{
        flex-shrink: 0;
        width:33.3%;
        height: 100%;
        position: relative;
        background: #FFFFFF;
       // border: 1px solid #8CA6B8;
        overflow: hidden;
        @include media-breakpoint-down(sm){
          height: 30vw;
        }
        @include media-breakpoint-down(xs){
          width: 50%;
          height: 45vw;
        }
        .item-inner{
          width: 100%;
          border-radius: rem(10px);
          height: 100%;
          padding: rem(40px 10px 20px 10px);
          border: 1px solid #8CA6B8;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        &:first-child{
          &:after{
            background-color: cl(purple);
          }
        }
        &:nth-child(2){
          &:after{
            background-color: cl(green);
          }
        }
        &:nth-child(3){
          &:after {
            background-color: cl(primary);
          }
        }
        &:nth-child(4){
          &:after{
            background-color: cl(red);
          }
        }
        &:nth-child(5){
          &:after {
            background-color: cl(yellow);
          }
        }
        &:after{
          content: "";
          top: 0;
          z-index: 2;
          left: 0;
          position: absolute;
          height: rem(12px);
          width: calc(100%);
          border-radius: rem(10px 10px 0px 0px);
        }
        img{
          width: rem(58px);
          height: rem(58px);
         // max-width: 32%;
          object-position: center top;
          margin-bottom: 1rem;
        }
        .ttl{
          @extend .ttu;
          font-weight: 700;
          font-size: rem(20px);
          line-height: rem(21px);
        }
      }
    }

  }
  .text-side{
    max-width: rem(504px);
    @include media-breakpoint-down(md){
      max-width: 100%;
    }
  }
}

.link{
  font-size: rem(16px);
  font-weight: 600;
}

.forms-section{
  padding:rem(160px 0 70px 0);

  h2{
    font-size: rem(44px);
    text-align: center;
  }
  p{
    text-align: center;
    padding: 0 0.5rem;
  }
  .pre-reg-form-wrap{
    margin: 0 auto;
    max-width: rem(700px);
    color: #333333;
    font-size: rem(20px);
    line-height: 1.5;
    letter-spacing: rem(0.5px);
    form{
      margin-top: rem(65px);
    }
    .btn{
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
      border-radius: rem(50px);
      line-height: rem(64px);
      font-size: rem(20px);
      font-weight: 500;
      margin-top: rem(20px);
    }
    .type-choose{
      //.visit-lbl{
      //  .ttu;
      //  color:@text;
      //  font-weight: 700;
      //  .font-size(16);
      //  .line-height(20);
      //  margin-bottom: 1rem;
      //  margin-right: 2.2rem;
      //
      //}
      .item{
        position: relative;
        margin-bottom: rem(18px);
        .rad {
          position: absolute;
          opacity: 0;
          right: 0;
          top: 50%;
        }
        .rad + label{
          min-height: rem(84px);
          background: #F5F6FA;
          border-radius: rem(10px);
          border: rem(2px solid #F5F6FA);
          color: rgba(51, 51, 51, 1);
          font-size: rem(20px);
          text-align: center;
          letter-spacing: rem(0.5px);
          display: flex;
          align-items: center;
          justify-content: center;
          padding:rem(20px 10px);
          position: relative;
          margin-bottom: 0;
          .circle{
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            border: rem(2px solid #00486C);
            @extend .pos-centerY;
            left: 0.8125rem;
            flex-shrink: 0;
            &:after{
              content: "";
              position: absolute;
              background-color: cl(primary);
              border-radius: 50%;
              border:rem(5px solid #DCE0EE);
              width: 100%;
              height: 100%;
              opacity: 0;
              top: 0;
              left: 0;
              flex-shrink: 0;
            }
          }
        }
        .rad:hover + label {

          cursor: pointer;
        }
        .rad:checked + label {
          background: #DCE0EE;
          border: rem(2px solid #00486C);
          color: cl(black);
          .circle{
            border-color: cl(primary);
            &:after{
              opacity: 1;
            }
          }
        }
        //.rad + label .icon {
        //  //.width(110);
        //  height: 2rem;
        //  background-repeat: no-repeat;
        //  background-position: 50% 50%;
        //  background-size: 100% auto;
        //  position: relative;
        //  z-index: 2;
        //  flex-shrink: 0;
        //  display: flex;
        //  justify-content: center;
        //  align-items: center;
        //}

      }
    }
    .form-scroll{
      margin-right: -30px;
      max-height: rem(500px);
      margin-bottom: 2rem;
      overflow: auto;
      @include media-breakpoint-down(md){
        max-height: unset;
        margin-right:unset;
      }
    }
  }
}
.contact-section{
  padding: rem(60px 0);
  .bg-text{
    color: rgba(0, 72, 108, 0.07);
    font-size: 6.9vw;
    line-height: 6.9vw;
    margin-top: -0.9vw;
    font-weight: 100;
    white-space: nowrap;
    text-align: center;
  }
  .pre-reg-form-wrap{
    margin: 0 auto;
    max-width: rem(700px);
    color: #333333;
    font-size: rem(20px);
    line-height: 1.5;
    letter-spacing: rem(0.5px);
    .btn{
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
      border-radius: rem(50px);
      line-height: rem(64px);
      font-size: rem(20px);
      font-weight: 500;
      margin-top: rem(20px);
    }
    .type-choose{
      //.visit-lbl{
      //  .ttu;
      //  color:@text;
      //  font-weight: 700;
      //  .font-size(16);
      //  .line-height(20);
      //  margin-bottom: 1rem;
      //  margin-right: 2.2rem;
      //
      //}
      .item{
        position: relative;
        margin-bottom: rem(18px);
        .rad {
          position: absolute;
          opacity: 0;
          right: 0;
          top: 50%;
        }
        .rad + label{
          min-height: rem(84px);
          background: #F5F6FA;
          border-radius: rem(10px);
          border: rem(2px solid #F5F6FA);
          color: rgba(51, 51, 51, 1);
          font-size: rem(20px);
          text-align: center;
          letter-spacing: rem(0.5px);
          display: flex;
          align-items: center;
          justify-content: center;
          padding:rem(20px 10px);
          position: relative;
          margin-bottom: 0;
          .circle{
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            border: rem(2px solid #00486C);
            @extend .pos-centerY;
            left: 0.8125rem;
            flex-shrink: 0;
            &:after{
              content: "";
              position: absolute;
              background-color: cl(primary);
              border-radius: 50%;
              border:rem(5px solid #DCE0EE);
              width: 100%;
              height: 100%;
              opacity: 0;
              top: 0;
              left: 0;
              flex-shrink: 0;
            }
          }
        }
        .rad:hover + label {

          cursor: pointer;
        }
        .rad:checked + label {
          background: #DCE0EE;
          border: rem(2px solid #00486C);
          color: cl(black);
          .circle{
            border-color: cl(primary);
            &:after{
              opacity: 1;
            }
          }
        }
        //.rad + label .icon {
        //  //.width(110);
        //  height: 2rem;
        //  background-repeat: no-repeat;
        //  background-position: 50% 50%;
        //  background-size: 100% auto;
        //  position: relative;
        //  z-index: 2;
        //  flex-shrink: 0;
        //  display: flex;
        //  justify-content: center;
        //  align-items: center;
        //}

      }
    }
    .form-scroll{
      margin-right: -30px;
      max-height: rem(500px);
      margin-bottom: 2rem;
      overflow: auto;
      @include media-breakpoint-down(md){
        max-height: unset;
        margin-right:unset;
      }
    }
  }
}